import React from "react"

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import SignUpEmail from "../../../components/signUpEmail";
import img1 from "../../../images/marketing-services/web-design/web-design-img-1.svg";
import img12 from "../../../images/marketing-services/web-design/web-design-img-12.svg";
import img13 from "../../../images/marketing-services/web-design/web-design-img-13.svg";
import img14 from "../../../images/marketing-services/web-design/web-design-img-14.svg";
import img15 from "../../../images/marketing-services/web-design/web-design-img-15.svg";
import AccordionComponent from "../../../components/AccordionComponent"


const webDesignPage = () => (
  <Layout css="webDesignPageCss">
    <SEO title="Boston Website Design And Development Agency"
         description={"Our Boston-based Website Design Agency can take your website to the next level. Turn visitors into customers with smart web design and up-to-date best practices."}/>
    <div className={"container hero section-padding-wrapper"}>
      <div className={"row align-items-center m-flex-direction-col-rev"}>
        <div className={"col-md-6"}>
          <h1><span className={"red"}>WEB DESIGN AND DEVELOPMENT </span>BOSTON</h1>
          <div className={"subtitle"}>
            <p>Almost every business in this era uses the internet to maximize their marketing strategies. Online
              marketing is not restricted to e-brands and e-businesses. Even brick-and-mortar companies use the internet
              to their advantage.</p>
            <p>Your ability to pull the market tides in your favor through online marketing is crucial. One of the
              leading points in e-marketing efforts in website designing. Avantcy Marketing offers web design services
              in Boston that will help you stay on top of your online marketing game.</p>
            <p>As a top Boston web design company, we help you build a website that you are proud to show off. In
              addition to creating a high-standard site for your brand, we engage in SEO practices for better
              visibility.</p>
          </div>
          <a href={"/contact#contact-today"} className={"button-red"}>REQUEST A CALL</a>
        </div>
        <div className={"col-md-6"}>
          <img alt={""} src={img1} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2>WEB DESIGN AND DEVELOPMENT JUST FOR YOU</h2>
      </div>
      <h3 className={"subtitle"}>Whether you’re a life coach, E-commerce owner, real estate agent, or commercial
        services owner, we have your back! With years of experience working with many sites, ranging from a simple
        personal
        site to SaaS applications, we're here to ensure that your site is just the way you wanted. What type of website
        do you want us to help you build?</h3>
      <img alt={""} src={img12} className={"w-100 m-pic-align m-hide"}/>
      <img alt={""} src={img15} className={"w-100 m-pic-align m-show d-none w-100-true"}/>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile"}>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img13} id={"choosing-pic"}/>
          </div>
          <div className={"flex-equal-width m-3 text-align-center"}>
            <h2>CONVERT MORE TRAFFIC INTO CUSTOMERS</h2>
            <p className={"text-align-left"}>Are you tired of seeing analytics reports that show significant traffic
              with little or no conversions to show for it? Avantcy Marketing's web design service in Boston can save
              you from being stuck.</p>
            <p className={"text-align-left"}>We can help you create a responsive website design to ensure that you are
              in sync with consumer preferences. Being in sync with customer needs drives traffic towards conversion
              since you have what they need. Additionally, with an outstanding website design agency like Avantcy, you
              can complement other online marketing efforts.</p>
          </div>
        </div>
        <AccordionComponent label="Search Engine Optimization">
          <p>Avantcy Marketing promotes SEO-friendly web design services to improve your visibility.</p>
        </AccordionComponent>
        <AccordionComponent label="Content Writing Services">
          <p>We help you get rid of obsolete sites that do not contain current information. We create unique, relevant,
            and comprehensive information that helps to keep your site updated.</p>
        </AccordionComponent>
        <AccordionComponent label="Site Maintenance">
          <p>Get your site running smoothly with scheduled, routine maintenance. These efforts help in improving
            traffic, security, and page experience.</p>
        </AccordionComponent>
        <AccordionComponent label="Analytics Reports">
          <p>Our team does our best to avoid working without monitoring results. Avantcy's site analysis will help us
            identify all the rooms for improvement and all your strengths. When we note them, we can optimize them to
            boost conversions and engagement.</p>
        </AccordionComponent>
        <AccordionComponent label="Brand Logo">
          <p>If you want to redesign your logo or get a new logo, Avantcy Marketing is ever ready to help you out. We
            help you design a logo that conveys your core values to help potential customers recognize you easily.</p>
        </AccordionComponent>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile"}>
        <div className={"flex-equal-width m-3 text-align-center"}>
          <h2>MOBILE WEB DESIGN</h2>
          <p className={"text-align-left"}>Web users carry out a vast majority of their internet activities with their
            mobile phones. However, many websites aren't mobile-friendly. In fact, Google statistics have it that up to
            24% of the top 1 million sites aren't mobile-friendly. Avantcy Marketing's web design Boston services help
            you adapt your site to customer behavioral patterns.</p>
          <p className={"text-align-left"}>Adapting for us means that our Boston web designer helps you set up a site
            that is accessible on all devices. This means that you should expect a mobile-friendly website to help your
            customers use your site better and effectively.</p>
          <p className={"text-align-left"}>Lastly, to amp up your conversion rates, we also ensure that there are
            defined call-to-actions to direct your customer's aright. Additionally, web design Boston CTAs make your
            site convenient for customer use.</p>
        </div>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img14} id={"choosing-pic"}/>
        </div>
      </div>
    </div>
    {/*<div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <SignUpEmail/>
      </div>
    </div>*/}
  </Layout>
);

export default webDesignPage;
